import React, { Dispatch, SetStateAction, useState } from 'react'
import { Button, Heading, InlineAlert, Paragraph } from 'suomifi-ui-components'
import RequestCredential from '../../components/RequestCredential'
import { SimplifiedPresentationPayload } from '../../api/vce-api/vce-api'
import { Link, useOutletContext } from 'react-router-dom'
import Poa, { PoaCredentialSdJwt } from '../credentials/Poa'

type Props = {}

const KycPOA = (props: Props) => {

  const { poaState } = useOutletContext<{ poaState: [boolean, Dispatch<SetStateAction<boolean>>]}>();

  const [poaCompleted, setPoaCompleted] = poaState;

  const storedPoa = poaCompleted ? localStorage.getItem("poa") : null;
  const [ poa, setPOA ] = useState<PoaCredentialSdJwt|undefined>(storedPoa !== null ? JSON.parse(storedPoa) : undefined); 
    

  const onCallback = (data: SimplifiedPresentationPayload) => {
    debugger
    localStorage.setItem("poa", JSON.stringify(data.credentials[0], null, 2));
    setPOA(data.credentials[0] as PoaCredentialSdJwt);
    setPoaCompleted(true);
  }

  const credentialClaims: string[] = ["given_name", "family_name","date_of_birth","proxied_permissions"];
  const credentialClaimsCSV = credentialClaims.join(",");

  return (
    <div>
      <Heading variant='h3'>Power of Attorney (PoA)</Heading>
      { !poaCompleted &&
      <>
        <Paragraph>Here you can present a Power of Attorney to demonstrate you have the permission to open a bank account for the company</Paragraph>
        
        {/* <InlineAlert status="neutral"> */}
        <Paragraph>If you are part of the EUCC legal presentative list and you are the only one in the list, you can skip this step.</Paragraph>
        {/* </InlineAlert> */}

        <RequestCredential callback={onCallback} showWalletAddress={true} credentialType='PoA' pathType='sd-jwt' showOptions={true}
          claimsCSV={credentialClaimsCSV}></RequestCredential>
      </>
      }
      { poa && poaCompleted &&  
        <Poa credential={poa}></Poa>
      }

      <br />
      <Link to="/kyc/summary" >
        <Button disabled={!poa}>Next step</Button>
      </Link>

      <Link to="/kyc/summary" style={{ marginLeft: "10px"}}>
        <Button title='PoA is not needed if the person is in the EUCC list.'>Skip</Button>
      </Link>

    </div>
    
  )
}

export default KycPOA