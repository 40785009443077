import React from 'react'
import { Heading, Paragraph } from 'suomifi-ui-components'

type Props = {}

const KycFinal = (props: Props) => {
  return (
    <div>
      <Heading variant='h3'>
        Thank You for your application
      </Heading>
      <Paragraph>
        As a bank we need to konw a customer, now we have received required identification documents from you.
        It will take few days to process the application. 
      </Paragraph>
      <Paragraph>
        We will contact you when the process is ready.
      </Paragraph>
    </div>
  )
}

export default KycFinal